import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Section from "../objects/Section"
import Container from "../objects/Container"
import TitleSection from "../objects/TitleSection" 
import TextSection from "../objects/TextSection" 

const NotFoundSection = styled(Section)`
   background-color: ${props => props.theme.color.grayLight};
`

const NotFoundContainer = styled(Container)`
   height: 70vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: 0;
   padding: 0 16px;
`

const Title = styled(TitleSection)`
   font-size: 4rem;
`

const Text = styled(TextSection)`
   font-size: 2.4rem;
   line-height: 4rem;
   margin-top: 2rem;
   text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundSection>
       <NotFoundContainer>
          <Title tagAs="h1" title="Erro 404" />
          <Text>
             Ops!! Página não encontrada... que tristeza!
          </Text>
          <TextSection>
             Para sair daqui escolha uma opção do nosso menu.
          </TextSection>
       </NotFoundContainer>
    </NotFoundSection>
  </Layout>
)

export default NotFoundPage
